﻿/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

// Careers specific styles
#careers {
    .default-intro {
        padding-bottom: 3.75rem;
    }

    .icons-intro {
        padding-top: 0;
    }

    .default-section {
        .img-container {
            position: relative;


            .img-container-inner {
                display: flex;
                justify-content: center;
                align-content: center;
                flex-direction: column;

                &::after {
                    display: none;
                }


                > div:first-of-type {
                    margin-top: auto;
                    margin-bottom: auto;
                    width: 100%;
                    padding-top: 56.25%;
                }


                iframe {
                    width: 100%;
                    height: calc(100% - 52px);
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                .caption {
                    @extend .text-large;
                    width: 100%;
                    text-align: center;
                    padding: 1rem 0;
                    font-weight: bold;
                }
            }
        }
    }

    #vacancies {
        h3 {
            text-align: center;
            margin-bottom: 3rem;
            margin-left: auto;
            margin-right: auto;
        }

        h5 {
            @extend .text-large;
            font-weight: bold;
            display: inline-block;
            margin-bottom: 0.5rem;
            margin-top: 1.5rem;
        }

        .job {
            padding-right: 1rem;

            .full-details {
                display: none;

                ul {
                    margin-bottom: 1rem;
                }

                a {
                    display: inline-block;
                    margin: 1rem 0;
                    padding-bottom: 4px;
                    font-weight: bold;
                }
            }

            .full-details-button {
                @extend a;
                display: inline-block;
                margin: 1rem 0;
                padding-bottom: 4px;
            }
        }
    }
}