/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

// Simple text intro

.default-intro {
    background-color: $lightgrey;
    padding: $standard-padding;

    @include media-breakpoint-down(sm) {
        padding: $standard-padding-mobile;
    }

    .container {
        width: $standard-width;
        max-width: none;

        @include media-breakpoint-only(xs) {
            width: 100%;
        }

        p:first-of-type {
            @extend .text-large;
            color: $colour-text-dark;
        }
    }
}