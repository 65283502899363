// Sass Mixins

@mixin absolute-centre {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

// Mixin for animation of desktop timeline
@mixin delayAnim($items, $selector, $delay, $wait) {
    @for $i from 1 through $items {
        $classes: ();

        @for $j from 1 through $i {
            $className: if($j == 1, #{"~ " + $selector}, #{"~ " + $selector});
            $classes: join($classes, unquote($className), space);
        }

        #{$classes} {
            animation-delay: $wait + $delay * $i;
        }
    }
}