/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

.contact {
    background-color: #f7f7f7;
    padding: $standard-padding;

    @include media-breakpoint-down(sm) {
        padding: $standard-padding-mobile;
    }

    .contact-form-container {
        width: calc(60px + 50vw);

        @include media-breakpoint-down(md) {
            width: calc(60px + 66.66vw);
        }

        @include media-breakpoint-down(sm) {
            width: 90%;
        }

        @include media-breakpoint-only(xs) {
            width: 100%;
        }

        form {
            display: flex;
            flex-direction: column;
            margin-top: 4rem;

            > .row {
                > .col-12 {
                    @include media-breakpoint-up(sm) {
                        &:nth-of-type(even) {
                            .form-group {
                                margin-left: 5%;
                            }

                        }
                    }
                }
            }

            input[type="text"], input[type="email"], input[type="tel"], textarea {
                display: block;
                background-color: transparent;
                padding: 0.75em 1em;
                border: none;
                border-bottom: 1px solid $grey;
                color: $colour-text;

                &:focus {
                    box-shadow: 0 0 2px $blue;
                }
            }

            input[type="text"], input[type="email"], input[type="tel"] {
                width: 95%;

                @include media-breakpoint-only(xs) {
                    width: 100%;
                }
            }

            @include media-breakpoint-up(md) {
                input:nth-child(even) {
                    margin-right: 0;
                }
            }

            textarea {
                height: 6em;
                width: 100%;
            }

            label {
                font-weight: bold;
                color: $colour-headings;
            }

            .form-group {
                margin-bottom: 2rem;
            }

            .form-check {
                margin-bottom: 1rem;

                @include media-breakpoint-only(xs) {
                    padding-left: 0;
                }
            }

            #contact-submit {
                margin: 2em auto 0 auto;
            }

            ::placeholder {
                color: $grey;
            }

            &.submitted {

                input:invalid {
                    box-shadow: 0 0 2px $red;
                    outline: $red auto 1px;
                }

                input[type="checkbox"]:invalid {
                    border-color: $red;
                    outline: none;
                }
            }
        }
    }
}

/* Custom checkboxes */
.checkbox {
    display: flex;
    align-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    > label {
        margin-left: 0.5em;
        margin-bottom: 0.125rem; /* Tiny bit of margin helps visually align the label with the checkbox */
        padding: 0.5rem 0.25rem;
        font-weight: normal;
        color: $colour-text;
    }

    > input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        flex-shrink: 0;
        height: 25px;
        width: 25px;
        border: 2px solid $darkgrey;
        border-radius: 4px;
        outline: none;
        transition-duration: 0.3s;
        cursor: pointer;
    }

    > input:hover {
        height: 25px;
        width: 25px;
        background-color: darken($lightgrey, 10%);
    }

    > input:checked + label::before {
        content: '\2713';
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(32.5%,42.5%);
        color: $blue;
        font-size: 1.25em;
        text-align: center;
        cursor: pointer;
        pointer-events: none;
    }

    > input:active {
        border: 2px solid #34495E;
    }
}

#contact .contact .container-fluid > p {
        margin-bottom: 4rem;
}