﻿/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

/* Converting pre-formatted privacy text into the site styles */
#privacy main, #privacy-modal {
    h1 {
        @extend h2;
        text-shadow: none;

        &:first-of-type {
            margin-top: 0;
            padding-left: 0;
        }
    }

    h2 {
        @extend h4;
        margin-top: 1em;
        margin-bottom: 0.5em;
    }

    h3 {
        @extend h6;
        margin-top: 1em;
    }

    ol li {
        margin-bottom: 0.25em;
    }
}

