﻿/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

// About specific styles

.values-video {
    background-color: white;
    padding-bottom: 7.5rem;

   
    .video-container {
        width: $standard-width;
        height: calc((120px + 66.66vw) * 0.5625); /* Correct height/width ratio for a normal video */

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    
}

#mission-values {
    .icons-intro {
        background-color: white;
        padding-top: 5rem;

        @include media-breakpoint-down(sm) {
            padding-top: 3.5rem;
        }

        .item {
            @include media-breakpoint-down(sm) {
                margin-bottom: 2.5rem;
            }

            .icon-container {

                .icon-circle {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &.t {
                        background-color: $team-blue;
                        border-color: $team-blue;
                    }

                    &.e {
                        background-color: $team-pink;
                        border-color: $team-pink;
                    }

                    &.a {
                        background-color: $team-yellow;
                        border-color: $team-yellow;
                    }

                    &.m {
                        background-color: $team-green;
                        border-color: $team-green;
                    }

                    img {
                        width: 50%;
                    }
                }
            }

            .text-container {
                .text-xl {
                    margin-bottom: 0.5em;
                }
            }
        }
    }
}