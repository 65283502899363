﻿/* IE11 fixes: general */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {

    img {
        flex-shrink: 0 !important;
    }




    /* IE11 fixes: awards */
    #awards {
        min-height: 0;

        .container {
            .item {
                .text-container {
                    width: 100%;
                }
            }
        }
    }





    /* IE11 fixes: carousel */
    #home-carousel {

        ol.carousel-indicators li {
            @include media-breakpoint-up(sm) {
                background: transparent;
            }
        }

        .carousel-content .img-container {
            display: table;
            justify-content: center;
            align-items: center;
            height: 100%;

            .img-container-inner {
                display: table-cell;
                height: 100%;
                vertical-align: middle;

                img {
                    display: inline-block;
                    vertical-align: middle;
                    height: auto;
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
        }

    }

    #home-carousel ol.carousel-indicators li .carousel-progress-bar,
    #careers-carousel ol.carousel-indicators li .carousel-progress-bar,
    #home-carousel .small-carousel-indicator .carousel-progress-bar,
    #careers-carousel .small-carousel-indicator .carousel-progress-bar {
        text-align: left;
    }

    /* IE11 fixes: contact */
    carouse
    .contact form .mx-auto {
        text-align: center;
    }


    /* Default sections */
    .default-section {
        @media only screen and (max-height: 1000px) {
            //min-height: 580px;
        }

        .section-container-inner {
            .text-container {
                flex: 1;
                width: 100%;
            }
        }
    }


    /* History */


    /* IE11 fixes: people */
    .directors-container-inner .img-container, .overlay-intro .img-container {
        display: block !important;

        img {
            height: auto;
        }
    }


    .team-members .directors-container-inner {
        display: block;
    }





    /* IE11 fixes: page blocks */
    .page-blocks {
        display: block;

        .row {
            > div {
                display: block;
            }
        }

        .item {
            display: block;

            .img-container {
                img {
                    width: 100%;
                }
            }

            .text-container {
                flex: 1;

                p {
                }
            }
        }
    }


    /* Footer */
    footer {
        .container {
            .row {
                > div {
                    display: block;
                }

                .flex-grow-0 {
                    flex-grow: 0.5 !important;
                }
            }

            .cellhire-logo {
                height: calc((120px + 10vw) / 3.57); /* The height:width ratio of the logo is 3.57:1 */
            }
        }
    }




    /* Icons intro */
    .icons-intro .container {
        .item {
            text-align: center;

            .icon-container {
                display: inline-block;
            }

            .text-container {
                display: inline-block;
                width: 100%;

                p {
                    flex: 1;
                }
            }
        }
    }


    #history {
        #timeline-desktop {
            .container {
                height: 600px;

                svg {
                    path {
                        stroke-dasharray: 0;
                        stroke-dashoffset: 0;
                        animation: fadeIn 1s;
                    }
                }
            }
        }

        #timeline-mobile {
            .container {
                .branch-group {
                    &.offices .branch-button {
                        width: 180px;
                    }

                    &.providers .branch-button {
                        width: 200px;
                    }

                    &.awards .branch-button {
                        width: 110px;
                    }

                    &.brands .branch-button {
                        width: 110px;
                    }
                }
            }
        }
    }


#careers-carousel {
    .carousel-inner {
        .item {
            .attribution::before {
                display: none;
            }
        }
    }
}

.side-nav a::after {
    display: none;
}
}

// Old browser message for IE9 and below
#old-browser {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 50px;
    z-index: 20001;
    background-color: $lightishgrey;
    box-shadow: 0 0 6px rgba($black, 0.1);

    div {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;

        i {
            font-size: 32px;
        }
    }
}