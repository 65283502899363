/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

/* 
    The 'default sections' are the sections with a paragraph of text next to an image. On XS screens this changes to position the text above the image.
    This works using Bootstrap 4 layout classes.

    The classes .a and .b determine the background colour. .a and .b alternate down the page, with .a sections having a white background and the text on the left, and the .b sections vice versa with a light grey background

    In a .a section the text container will be contained in a <div> with the classes: "col-12 col-sm-6"
    The image container will be contained in a <div> with the classes: "col-12 col-sm-6 col-md-5 offset-md-1"

    In a .b section the text container will be contained in a <div> with the classes: "col-12 col-sm-6 col-md-5 offset-md-1 order-1 order-sm-2" - this puts the text on the right on large screens, but keeps it on top on XS screens
    The image container will be ocntained in a <div> with the classes: "col-12 col-sm-6 order-2 order-sm-1"

    Animations should also be adjusted so that the left-most container has fadeInLeft and the right-most container has fadeInRight

    If the page is particularly long and the aux-nav needs to be added, the sections also need to be wrapped in the .sections-wrapper. This will shift them to the right to make space for the side-nav, and position the side-nav correctly.
*/

.default-section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: space-around;
    align-items: center;
    height: auto;
    width: 100%;
    padding: $standard-padding;

    @include media-breakpoint-down(sm) {
        padding: $standard-padding-mobile;
    }

    &.a {
        background-color: white;
    }

    &.b {
        background-color: $lightgrey;
    }    

    .section-container-inner {
        width: $standard-width;

        @include media-breakpoint-only(xs) {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
        }

        > .row > div {
            display: flex;
        }

        .text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            padding-right: 1rem;

            h3 {
                margin-bottom: 2rem;
            }

            p {
                margin-bottom: 2rem;

                &:last-of-type {
                    margin-bottom: 1rem;
                }

            }

            @include media-breakpoint-only(xs) {
                a {
                    margin-bottom: 1rem;
                }
            }
        }

        .img-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            width: 100%;

            .img-container-inner {
                position: relative;
                width: 100%;

                /* This makes the images square */
                &::after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }
            }

            @include media-breakpoint-up(xl) {
                width: 100%;
            }

            @include media-breakpoint-only(xs) {
                margin-top: 1.5rem;
            }

            @include media-breakpoint-only(xs) {
                width: 100%;
            }

            img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}

/* Styles on pages with the aux-nav (where the sections below the intro should be inside the .sections-wrapper) */
.sections-wrapper {

    .default-section {
        position: relative;
        
        @include media-breakpoint-up(lg) {
             .section-container-inner {
                /* Shifting the content to the right to fit the side navbar in */
                position: relative;
                left: 100px;
                width: calc(40px + 66.66vw);
            }

          
        }
    }
}

// Large sections combine several default sections into one
.large-section {
    .default-section {

        &.a {
            background-color: white;
        }

        &.b {
            background-color: white;
        }

        padding: 1.5rem 0;
        min-height: 40vh;

        &:first-of-type {
            padding: $standard-padding;

            @include media-breakpoint-down(sm) {
                padding: $standard-padding-mobile;
            }

            padding-bottom: 1.5rem;
        }

        &:last-of-type {
            padding: $standard-padding;

            @include media-breakpoint-down(sm) {
                padding: $standard-padding-mobile;
            }

            padding-top: 1.5rem;
        }
    }
}