/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

section.bottom-info {
    background-color: $lightishgrey;
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 15px;

    p {
        @extend .text-xl;
    }
}