﻿/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

// About specific styles

section.offices {
    padding: $standard-padding;

    @include media-breakpoint-down(sm) {
        padding: $standard-padding-mobile;
    }


    .offices-container {
        display: flex;
        width: $standard-width;
        justify-content: space-between;
        flex-wrap: wrap;

        @include media-breakpoint-down(md) {
            width: $large-width;
        }

        @include media-breakpoint-down(sm) {
            width: 90%;
        }

        h3 {
            width: 100%;
            text-align: center;
        }

        > p {
            margin-bottom: 4rem;
            width: $small-width;

            @include media-breakpoint-down(md) {
                width: 90%;
            }
            @include media-breakpoint-only(xs) {
                width: 100%;
            }
        }

        .office {
            width: 30%;
            margin-bottom: 5rem;

            @include media-breakpoint-down(md) {
                width: 47.5%;
            }

            @include media-breakpoint-down(xs) {
                width: 100%;
            }

            h4 {
                font-weight: normal;
                margin-bottom: 0;
            }

            hr {
                margin: 1rem 0;
                background-color: $grey;
                border: none;
                height: 1px;
            }

            p:first-of-type {
                margin-bottom: 1.5em;
            }

            p {
                display: flex;
                align-items: center;

                i {
                    font-size: 1.25em;
                    margin-right: 0.5rem;
                }
            }
        }
    }
}