/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

// Typography rules

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Fonts */
$font-primary: 'Proxima-soft', 'Proxima-nova', sans-serif;
$font-headings: 'Proxima-nova', 'Proxima-soft', sans-serif;

html {
    font-size: 16px;
}

body {
    font-family: $font-primary;
    font-size: 1rem;
    color: $colour-text;
}

#{$a-tags} {
    text-decoration: underline;
}

#{$a-tags-hover} {
    text-decoration: none;
}

a {
    position: relative;
    color: $blue;
    transition: color 0.3s;
    border-bottom: 1px solid lighten($blue, 30%);
    text-decoration: none;
}

a:hover {
    color: darken($blue, 10%);
    text-decoration: none;
}



main p a, main li a, main span a, main nav a, footer a {
    &::after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        width: 0;
        background-color: darken($blue, 10%);
        transition: 0.3s ease all;
    }

    &:hover::after {
        width: 100%;
    }
}

/* White links */
footer a, nav a {
    color: white;
    border-color: darken(white, 50%);

    &:hover {
        color: darken($white, 20%);
    }

    &::after {
        background-color: darken($white, 20%);
    }
}
/* Aux nav links */
.side-nav a, .top-nav a {
    border-color: $lightishgrey;

    &:hover {
        color: gray;
    }

    &::after {
        background-color: gray;
    }
}


a.no-underline {
    position: static;
    border: none;

    &::after {
        width: 0 !important; /* Ensures that the pseudo-element (underline) never displays */
    }
}


/* Selection highlights */
::selection {
    background: lighten($blue,45%);
    color: $colour-text-dark;
}

footer ::selection, nav ::selection, header ::selection {
    background: lighten($blue, 32.5%);
    color: white;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font-headings;
    color: $colour-headings;
}

h1 {
    font-size: $size-h1;
    font-weight: 600;
    line-height: 1.3;
    color: white;
    text-shadow: 0 0 6px rgba($black, 0.5);

    @include media-breakpoint-only(xs) {
        font-size: 3rem;
    }
}

h2 {
    font-size: $size-h2;
    font-weight: 600;
    margin-bottom: 3rem;
}

h3 {
    font-size: $size-h3;
    font-weight: 700;
    margin-bottom: 2rem;

    @include media-breakpoint-only(xs) {
        font-size: 2.5rem;
    }
}

h4 {
    font-size: $size-h4; 
    font-weight: 600;
    width: auto;
    margin-bottom: 0.75em;
}

h6 {
    font-size: $size-h6; 
    font-weight: bold;
    margin-bottom: 0.5em;
}

p, textarea, input, .checkbox span, li span, label, .label {
    font-size: $size-p;
    line-height: 140%;
    color: $colour-text;
    font-weight: normal;

    @include media-breakpoint-only(xs) {
        font-size: 1rem;
    }
}

.lh, lh, li {
    font-size: $size-p;
}

p {
    margin-bottom: 1em;
}
p:last-of-type {
    margin-bottom: 0;
}

footer li:not(.lh) {
    // add a lower font size
}

.text-medium {
    font-size: $size-p-l;
}

.text-large, .btn {
    font-size: $size-p-l;

    @include media-breakpoint-only(xs) {
        font-size: 1.125rem;
    }
}

.text-xl {
    font-family: $font-headings;
    font-size: $size-p-xl; 
    font-weight: 600;
    color: $colour-text;
}