/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

.modal {

    .modal-dialog {
        max-width: 90%;

        @include media-breakpoint-only(xs) {
            height: 90vh;
        }
    }

    .modal-content {
        background-color: $lightgrey;
        overflow-y: scroll;

        .modal-header {
            position: relative;
            display: flex;
            flex-direction: column;
            text-align: center;
            align-content: center;
            align-items: center;
            justify-content: center;
            border: none;

            .close {
                position: absolute;
                right: 3rem;
                top: 50%;
                background-color: transparent;
                border: none;
                -webkit-appearance: none;
                padding: 0;

                i {
                    font-size: 2em;
                }
            }
        }
    }
}

#privacy-modal {

    .modal-dialog {
        max-width: 50%;

        @include media-breakpoint-down(lg) {
            max-width: 67%;
        }

        @include media-breakpoint-down(md) {
            max-width: 90%;
        }

        @include media-breakpoint-only(xs) {
            width: 100%;
            max-width: none;
        }


        .modal-content {
            padding: 0;
            overflow-y: visible;

            @include media-breakpoint-only(xs) {
                padding: 1rem;
            }

            .modal-header {
                padding: 3rem;

                @include media-breakpoint-down(sm) {
                    padding: 2rem;
                }

                @include media-breakpoint-only(xs) {
                    padding: 1rem;
                }

                @media only screen and (max-height: 450px) {
                    padding: 0 1rem;
                }


                h2 {
                    margin-top: 1rem;
                }
            }

            .modal-body {
                padding: 0 3rem 1rem 3rem;

                @include media-breakpoint-down(sm) {
                    padding: 0 2rem 1rem 2rem;
                }

                @include media-breakpoint-only(xs) {
                    padding: 0 1rem 1rem 1rem;
                }

                overflow-y: scroll;
            }

            .modal-footer {
                padding: 3rem;

                @include media-breakpoint-down(sm) {
                    padding: 2rem;
                }

                @include media-breakpoint-only(xs) {
                    padding: 1rem;
                }

                @media only screen and (max-height: 450px) {
                    padding: 0 1rem;
                }


                button {
                    @extend .mx-auto;
                }
            }
        }
    }
}

body.modal-open, .modal-open .navbar-fixed-top, .modal-open .navbar-fixed-bottom {
    padding-right: 0 !important;
}

.modal {
    overflow-y: auto;
}