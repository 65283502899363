/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

#awards {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    padding: $standard-padding;
    background-color: $lightishgrey;

    @include media-breakpoint-down(sm) {
        padding: $standard-padding-mobile;
    }

    .container {
        display: flex;
        justify-content: space-evenly;
        align-content: center;
        align-items: flex-start;
        width: $standard-width;
        text-align: center;

        > div {
            width: 100%;
        }

        @include media-breakpoint-down(sm) {
            width: 70%;
        }

        @include media-breakpoint-only(xs) {
            width: 100%;
        }

        .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
            height: 100%;
            padding: 0 1rem;

            .icon-container {
                position: relative;
                height: calc(90px + (100vw / 50));
                width: calc(90px + (100vw / 50));
                margin: 1.5rem 0 1rem 0;
                transition: all 0.2s;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .text-container {
                display: flex;
                flex-grow: 1;
                justify-content: center;
                align-items: center;
                color: $darkgrey;

                p {
                    transition: color 0.2s;
                    font-weight: 600;

                    span:first-of-type {
                        display: block;
                    }
                }
            }
        }
    }
}
