/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

html {
    scroll-behavior: smooth; 
    transition-duration: 5000ms;
    overflow-x: hidden;
}

body {
    box-sizing: border-box;
    overflow-x: hidden;
}
a {
    white-space: nowrap;
}
.grecaptcha-badge {
    visibility: hidden;
}

ul.text-list {
    lh {
        color: $colour-headings;
        @extend .text-large;
        font-weight: bold;
        display: inline-block;
        margin-bottom: 0.75rem;
        margin-top: 0.75rem;
        line-height: 140%;
    }

    li {
        list-style: disc;
        margin-bottom: 0.5rem;
        margin-left: 1rem;
        line-height: 140%;
    }
}

.flex-break {
    display: flex;
    flex-basis: 100%;
    height: 0;
}

// Special utility classes used to squish the side nav text on /technology, avoiding overlap
.squish-lg-1600 {
    @media only screen and (max-width: 1600px) and (min-width: 992px) {
        letter-spacing: -0.75px;
    }
}
.squish-lg-1050 {
    @media only screen and (max-width: 1050px) and (min-width: 992px) {
        letter-spacing: -0.75px;
    }
}