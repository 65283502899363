
/* UTILITIES
 ================================*/
.align-to-right{
	margin-left: auto !important;
}
.align-to-left{
	margin-right: auto !important;
}
.margin-top{
	margin-top: 1rem !important;
	
	@media (min-width: $landscape-width){
		margin-top: 0 !important;
	}
}
.hide-on-portrait{	
	@media (max-width: $landscape-width - 1){
		display: none !important;
	}
}
.hide-on-landscape{	
	@media (min-width: $landscape-width){
		display: none !important;
	}
}

#navigation {
	transition: top 0.3s ease-in-out;
	z-index: 12;

	&.fixed {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;

		&.hidden {
			top: -80px;

			@include media-breakpoint-down(md) {
				top: -60px;
			}
		}
	}
}
@media only screen and (max-width: 1500px) {
	/* Causing the global sites dropdown to appear on the left when there is not enough room on the right */
	.navigation-dropdown .navigation-dropdown.global-sites-dropdown {
		right: 100%;
		left: auto;
	}
}