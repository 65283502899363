/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

// Image intro

.image-intro {
    padding: $standard-padding;

    @include media-breakpoint-down(sm) {
        padding: $standard-padding-mobile;
    }

    @include media-breakpoint-only(xs) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .container {
        @extend .rounded;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: $large-width;
        height: 100%;
        padding: $standard-padding;
        margin-right: auto;
        margin-left: auto;


        @include media-breakpoint-down(sm) {
            padding: $standard-padding-mobile;
        }

        @include media-breakpoint-only(xs) {
            width: 100%;
        }

        .text-block {
            padding: 3rem;
            background-color: white;
            text-align: center;
            max-width: 60%;

            &:first-of-type {
                margin-bottom: 2rem;
            }

            @include media-breakpoint-down(sm) {
                max-width: 80%;
            }

            @include media-breakpoint-only(xs) {
                padding: 2rem;
            }

            @media only screen and (max-width: 400px) {
                max-width: 90%;
                padding: 1.5rem;
            }
        }
    }
}