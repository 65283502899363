/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

#timeline-desktop {
    width: 100%;
    padding: $standard-padding;

    @include media-breakpoint-down(md) {
        display: none;
    }

    @include media-breakpoint-down(sm) {
        padding: $standard-padding-mobile;
    }


    .container {
        position: relative;
        width: 90%;
        max-width: none;

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        img {
            height: 75%;
            z-index: -1;
            @include absolute-centre;
        }
    }

    svg {
        animation-name: draw;
        animation-duration: 5s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;

        text {
            font-family: 'proxima-soft';
        }

        path {
            stroke-dasharray: 3000px;
            stroke-dashoffset: 3000px;
            animation-fill-mode: forwards;
        }

        #main-line path {
            animation-duration: 5s;
            animation-delay: 0s;
        }

        #offices-line path {
            animation-duration: 3.5s;
            animation-delay: 0.5s;
        }

        #providers-line path {
            animation-duration: 4s;
            animation-delay: 0.75s;
        }

        #awards-line path {
            animation-duration: 3s;
            animation-delay: 1.25s;
        }

        #brands-line path {
            animation-duration: 3s;
            animation-delay: 1.25s;
        }


        circle, circle + path {
            opacity: 0;
        }

        .delayed-circle {
            opacity: 0;
            animation-name: appear;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-delay: 3s;
            @include delayAnim(30, '.delayed-circle', 0.15s, 3);

            * {
                opacity: inherit;
            }
        }

        .delayed-line {
            opacity: 0;
            animation-name: appear;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-delay: 2s;
            @include delayAnim(40, '.delayed-line', 0.09s, 2);
        }

        .delayed-text {
            opacity: 0;
            animation-name: appear;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-delay: 1s;
            @include delayAnim(40, '.delayed-text', 0.08s, 1);
        }
    }
}

#timeline-mobile {
    padding: 4em 0 6em 0;
    overflow: hidden;

    @include media-breakpoint-up(lg) { /* Hide the mobile version on larger screens */
        display: none;
    }

    p {
        font-size: 16px;
    }

    .text-large {
        font-size: 20px;
    }

    img {
        pointer-events: none;
    }

    .container-outer {
        position: relative;
        padding: 50px 50px 0 50px;
        overflow: hidden;

        @media only screen and (min-width: 600px) {
            /* Left padding brings the timeline points away from the left edge on medium screens. No right padding because this affects the branch button in the top-right and its positioning in the un-fixed state */
            padding-left: calc(50px + (100vw - 600px) / 3);
        }
    }

    .container {
        height: 500px;
        width: 100%;
        margin: 0;
        position: relative;
        top: 0;
        padding: 0;
        transition: top 2s ease-in-out;
        transition-property: top, height;
        max-width: none;

        .main-line {
            position: absolute;
            top: 0;
            left: 0;
            transform: translateX(-50%);
            transition: left 2s ease-in-out;

            img {
                width: 10px;
            }
        }

        .branch-group {
            position: absolute;
            top: 0%;
            width: 100%;
            left: 0;
            transition: opacity 1s ease-in-out;

            &.offices {
                top: 0%;
            }

            &.providers {
                top: 125px;
            }

            &.awards {
                top: 250px;
            }

            &.brands {
                top: 375px;
            }

            .dot {
                position: absolute;
                top: 0;
                left: 0;
                height: 28px;
                width: 28px;
                transform: translate(-50%, -50%);
                transition: left 2s ease-in-out;
                z-index: 10;
            }

            .branch-line {
                position: absolute;
                height: 1512px;
                width: 1000px;
                top: 0;
                left: 0;
                transform: translateY(-5px);
                z-index: 8;
                transition: left 2s ease-in-out;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                }
            }

            &.offices .branch-button {
                background-color: $team-blue;
            }

            &.providers .branch-button {
                background-color: $team-pink;
            }

            &.awards .branch-button {
                background-color: $team-yellow;
            }

            &.brands .branch-button {
                background-color: $team-green;
            }

            .button-container {
                display: inline-block;
            }

            .branch-button {
                display: flex;
                align-items: center;
                position: absolute;
                top: 0;
                right: 90%;
                height: 40px;
                padding: 0.35em 0.7em;
                transform: translate(100%,-50%);
                z-index: 12;
                cursor: pointer;
                transition-duration: 2s;
                transition-timing-function: ease-in-out;
                transition-property: transform, right, opacity;

                span {
                    font-weight: 600;
                    color: white;
                    white-space: nowrap;
                    text-align: center;

                    i {
                        opacity: 1;
                        width: 0.5em;
                        margin-left: 0.5em;
                        transition: all 2s;

                        i::before {
                            width: 0.5em;
                            opacity: 1;
                            transition: all 2s;
                        }
                    }
                }
            }
        }

        .timeline-content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            transition: left 2s ease-in-out;

            &.offices {
                top: 0;

                .nub {
                    background-image: url(../../assets/images/svg-components/timeline/nub-blue.svg);
                }
            }

            &.providers {
                top: 125px;

                .nub {
                    background-image: url(../../assets/images/svg-components/timeline/nub-pink.svg);
                }
            }

            &.awards {
                top: 250px;

                .nub {
                    background-image: url(../../assets/images/svg-components/timeline/nub-yellow.svg);
                }
            }

            &.brands {
                top: 375px;

                .nub {
                    background-image: url(../../assets/images/svg-components/timeline/nub-green.svg);
                }
            }

            .timeline-point {
                display: inline-block;
                position: absolute;

                &:nth-of-type(1) {
                    top: 100px;
                }

                &:nth-of-type(2) {
                    top: 200px;
                }

                &:nth-of-type(3) {
                    top: 300px;
                }

                &:nth-of-type(4) {
                    top: 400px;
                }

                &:nth-of-type(5) {
                    top: 500px;
                }

                &:nth-of-type(6) {
                    top: 600px;
                }

                &:nth-of-type(7) {
                    top: 700px;
                }

                &:nth-of-type(8) {
                    top: 800px;
                }

                &:nth-of-type(9) {
                    top: 900px;
                }

                &:nth-of-type(10) {
                    top: 1000px;
                }

                &:nth-of-type(11) {
                    top: 1100px;
                }

                &:nth-of-type(12) {
                    top: 1200px;
                }

                &:nth-of-type(13) {
                    top: 1300px;
                }

                &:nth-of-type(14) {
                    top: 1400px;
                }

                &:nth-of-type(15) {
                    top: 1500px;
                }

                .text-container {
                    position: relative;
                    top: 0;
                    left: 36px;

                    p:first-of-type {
                        color: $grey;
                        margin: 0;
                    }

                    p:nth-of-type(2) {
                        font-weight: 600;
                        margin: 0;
                    }
                }
            }

            .nub {
                position: absolute;
                left: 0;
                top: 50%;
                height: 10px;
                width: 20px;
                transform: translateY(-50%);
            }
        }

        .timeline-back {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: -50%;
            height: 40px;
            width: 40px;
            background-color: #707070;
            color: white;
            transform: translateY(-50%);
            cursor: pointer;
            z-index: 12;

            a {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }

            i {
                pointer-events: none;
                ;
            }
        }
    }

    .off-left {
        transition: left 2s ease-in-out;
        left: -990px !important;
    }

    .off-right {
        transition-duration: 2s;
        transition-timing-function: ease-in-out;
        transition-property: margin-right, right;
        right: -990px !important;
    }

    .off-right-l { /* Because different elements need different right/left alignments, it's necessary to have a second class which does the same thing, but uses the 'left' property instead of 'right' for smooth animation */
        left: 990px !important;
    }

    .right-adjust {
        transition: right 2s ease-in-out;
        right: 0 !important;
        transform: translate(0,-50%) !important;

        &.branch-button {
            cursor: none;
            pointer-events: none;

            i {
                width: 0 !important;
                opacity: 0 !important;
                margin-left: 0 !important;

                &::before {
                    width: 0 !important;
                    opacity: 0 !important;
                }
            }
        }
    }

    .hidden {
        transition: opacity 1s ease-in-out;
        opacity: 0;
    }
}

#timeline-mobile .timeline-fixed {
    position: fixed !important;
    top: 20px !important;
    right: 0 !important;
    transition: none;
    transform: translateY(50%);

    &.branch-button {
        margin-right: 50px !important;

        &.nudged {
            margin-top: 80px;
        }
    }

    &.timeline-back {
        transform: translate(calc(-100% - 10px), -50%) !important;

        &.nudged {
            margin-top: 80px;
        }
    }
}

@media only screen and (max-width: 400px) {
    #timeline-mobile {

        .container-outer {
            padding-left: 25px;
            padding-right: 25px;
        }

        .timeline-fixed {
            &.branch-button {
                margin-right: 25px !important;
            }

            &.timeline-back {
                transform: translate(calc(-100% + 16px), -50%) !important;
            }
        }
    }
}
