/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

// Intro with semi-transparent colour on top of an image

.underlay-intro {
    width: 100%;

    .container {
        background-image: url('../images/skyscraper.jpg');
        background-size: cover;
        background-position: center;
        width: 100%;
        max-width: none;
        padding: 0;
        margin: 2.5rem 0;


        &:first-of-type {
            background-position-y: 25%;


            .colour {
                background-color: rgba(0,90,125,0.5);
            }
        }


        &:nth-of-type(2) {
            background-position-y: 65%;

            .colour {
                background-color: rgba(0,165,6,0.5);
            }
        }

        .colour {
            display: flex;
            height: 100%;
            min-height: 225px;
            width: 100%;

            > div {
                width: 100%;
                padding: 0;
                margin: 0;

                > div {
                    padding: 0;
                    margin: 0;
                }
            }

            .heading-container {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                @include media-breakpoint-only(xs) {
                    padding: 1rem 0;
                }

                h3 {
                    color: white;
                    margin-bottom: 0;
                }
            }

            .text-container {
                background-color: rgba(255,255,255,0.75);
                display: flex;
                align-items: center;
                padding: 2.5rem 5rem;
                height: 100%;

                @include media-breakpoint-up(sm) {
                    min-height: 225px;
                }

                @include media-breakpoint-down(sm) {
                    padding: 2.5rem;
                }

                p {
                    @extend .text-large;
                }
            }
        }
    }
}
