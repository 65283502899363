/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

/* Images */
.rounded {
    border-radius: 20px !important; /* Important overrides Bootstrap */
}
.shadowed {
    box-shadow: 0px 0px 3px $lightishgrey;
}