/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

/* The auxiliary nav that appears on long pages. Side navigation is for large screens, top navigation is for small screens. */
/* The sections wrapper, which wraps the default sections but not the intro, allows for correct positioning of the aux-nav. */
.sections-wrapper {
    position: relative; /* Allows .side-nav-container to be absolutey positioned */
}

.side-nav-container {
    position: absolute;
    height: auto;
    width: 100vw;
    top: 0;
    left: 0;
    pointer-events: none;
}

.side-nav {
    position: absolute;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    pointer-events: auto;

    @media only screen and (max-width: 1600px) {
        left: 4%;
    }

    &.fixed {
        position: fixed;
        left: 5%;
        top: 50%;
        transform: translateY(-50%);
        width: auto;

        @media only screen and (max-width: 1600px) {
            left: 4%;
        }
    }

    li {
        margin-bottom: 1em;

        a {
            color: $grey;

            &.active {
                color: $colour-text;
                font-weight: 600;
            }
        }
    }

    &.bottom {
        display: block;
        position: absolute;
        left: 5%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;

        @media only screen and (max-width: 1600px) {
            left: 4%;
        }
    }

    @include media-breakpoint-down(lg) {
        left: 2.5%;

        &.fixed {
            left: 2.5%;
        }

        &.bottom {
            left: 2.5%;
        }
    }

    &.hidden {
        display: none;
    }
}

.top-nav {
    position: fixed;
    top: -55px;
    left: 0;
    width: 100%;
    background-color: $lightishgrey;
    z-index: 10;
    transition: top 0.3s ease-in-out;




    &.visible {
        top: 80px;

        @include media-breakpoint-down(md) {
            top: 60px;
        }
    }

    &.flush {
        top: 0;
    }


    .top-nav-header {
        padding: 1em;
        cursor: pointer;

        p {
            font-weight: 600;
        }

        p, i {
            display: inline-block;
            margin: 0;
        }
    }

    .top-nav-content {
        display: none;
        margin: 0 1em;
        padding: 1em 0;
        border-top: 1px solid $grey;
    }

    li {
        margin-bottom: 0.75em;

        a {
            color: $grey;

            &.active {
                color: $colour-text;
                font-weight: 600;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .side-nav {
        display: none !important; /* Overrides all jQuery */
    }
}

@include media-breakpoint-up(lg) {
    .top-nav {
        display: none !important; /* Overrides all jQuery */
    }
}
