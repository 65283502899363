/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

// Footer
footer {
    width: 100%;
    padding: $standard-padding-mobile;
    background-color: $darkblue;
    color: white;

    p {
        color: white;
    }

    .container {
        width: calc(120px + 75vw);

        @include media-breakpoint-down(sm) {
            width: 90%;
        }

        @include media-breakpoint-only(xs) {
            width: 95%;
        }

        .cellhire-logo {
            width: calc(120px + 2.5vw);

            @include media-breakpoint-down(sm) {
                width: calc(120px + 10vw);
            }
        }

        ul {

            .social-media-icons {

                i {
                    font-size: 1.5rem;
                }
            }

            li {
                margin-bottom: 0.75em;

                a {
                    white-space: nowrap;
                    display: inline-block;
                    font-weight: normal;

                    &:hover {
                        color: darken($lightishgrey,10%);
                    }
                }
            }

            li.lh {
                font-weight: bold;
            }
        }

        .global-sites {
            display: inline-flex;
            flex-wrap: nowrap;
            cursor: pointer;

            span {
                white-space: nowrap;
            }

            .fa-globe {
                font-size: 1.5em;
            }

            .fa-chevron-down {
                display: inline-flex;

                &::before {
                    align-self: center;
                }
            }
        }

        .global-sites-list {
            display: none;

            li {
                display: flex;
                align-items: center;
                margin-bottom: 0.25em;

                a {
                }
            }

            img {
                height: 1.4em;
                border-radius: 0;
                margin-right: 0.75em;
            }
        }
    }

    hr {
        background-color: $bluegrey;
        border-color: $darkbluegrey;
        color: $bluegrey;
        margin: 1rem 0 4rem 0; /* The footer links have a 3rem margin-bottom, so the 1rem is added to that to balance the spacing */
    }

    .copyright-info {
        p {
            font-size: $size-p-s;
        }
    }

    .recaptcha-note {
        margin-top: 0.5em;
        color: lighten($bluegrey, 20%);

        a {
            color: lighten($bluegrey, 30%);
            border-color: lighten($bluegrey, 30%);

            &::after {
                background-color: lighten($bluegrey, 20%);
            }
        }
    }
}