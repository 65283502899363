﻿/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

#submitted {

    section {
        padding: $standard-padding;

        @include media-breakpoint-down(sm) {
            padding: $standard-padding-mobile;
        }

        .container {
            @extend .mx-auto;
            text-align: center;
            width: $standard-width;

            @include media-breakpoint-down(md) {
                width: 90%;
            }

            @include media-breakpoint-only(xs) {
                width: 95%;
            }

            > div {
                display: flex;
                justify-content: center;
            }

            p {
                margin-bottom: 3rem;
            }
        }
    }
}