/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

// Intro with text overlaid onto images
/*Testtesttest*/
.overlay-intro {
    display: flex;
    align-items: center;
    min-height: 50vh;
    padding: $standard-padding;
    background-color: $lightgrey;

    @include media-breakpoint-down(sm) {
        padding: $standard-padding-mobile;
    }

    .overlay-container {
        @extend .mx-auto;
        position: relative;
        width: $standard-width;
    }

    h2 {
        margin-bottom: 1rem;
    }

    .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: none; /* Go away, Bootstrap */

        .img-container {
            width: 50%;

            img {
                height: 100%;
                width: 100%;
            }
        }

        .text-container {
            position: absolute;
            right: 0;
            width: 60%;
            padding: 2em;
            background-color: white;


            .header {
                display: flex;
                align-items: center;
                margin-bottom: 1em;
                justify-content: space-between;
            }

            h4 {
                margin-bottom: 0.5em;
                margin-right: 0.5em;
            }

            .icon-container {
                flex-shrink: 0;
                position: relative;
                height: calc(40px + (100vw / 200));
                width: calc(40px + (100vw / 200));
                margin: 1.5rem 0 1rem 0;
                transition: all 0.2s;

                .icon-circle {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    height: 100%;
                    width: 100%;
                    border: 5px solid lighten($blue, 40%);
                    border-radius: 50%;
                    background-color: $blue;

                    a {
                        display: inline-block;
                        height: 100%;
                        width: 100%;
                    }
                }

                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    pointer-events: none;
                }

                i::before {
                    font-size: calc(1rem + (100vw / 200));
                    color: $lightgrey;
                }
            }
        }

        @include media-breakpoint-down(md) {
            .img-container {
                width: 80%;
            }
        }

        @include media-breakpoint-down(sm) {
            width: 95%;

            .img-container {
                width: 100%;
            }

            .text-container {
                width: 80%;
                top: 40vmin;
            }
        }

        @include media-breakpoint-only(xs) {
            flex-direction: column;
            margin-bottom: -30vmin;


            .text-container {
                position: relative;
                right: auto;
                top: -30vmin;
                width: 90%;
            }
        }

        @media only screen and (max-width: 400px) {
            margin-bottom: -15vmin;

            .text-container {
                width: 95%;
                top: -15vmin;
            }
        }
    }
}

.overlay-intro.twin {
    min-height: 60vh;

    .overlay-container {
        width: $extra-large-width;
        max-width: none;
    }

    .item {
        width: 100%;
        position: relative;

        .img-container {
            width: 60%;
        }

        .text-container {
            width: 55%;
            top: 50%;
            transform: translateY(-50%);
            padding: 1.75em;
        }
    }
    /* Styles that take effect when the two intro items are next to each other */
    @include media-breakpoint-up(xl) {
        .item {
            padding-right: 1em;
        }
    }

    /* Styles that take effect when the two intro items are NOT next to each other */
    @include media-breakpoint-down(lg) {
        .item {
            width: $standard-width;
        
            &.a {
                padding-bottom: 2em;
            }

            &.b {
                padding-top: 2em;
                padding-bottom: 2em;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .item {
            width: 95%;

            .img-container {
                width: 80%;
            }

            .text-container {
                width: 50%;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .item {

            &.b {
                padding-top: 5em;
            }

            .img-container {
                width: 100%;
            }

            .text-container {
                width: 80%;
                top: auto;
                bottom: -3rem;
                transform: translateY(0);
            }
        }
    }

    @include media-breakpoint-down(xs) {
        .item {
            margin-bottom: -20vmin;
            width: 100%;

            &.b {
                padding-top: 2em;
            }

            .text-container {
                position: relative;
                right: auto;
                top: -20vmin;
                width: 95%;
                transform: translateY(0);
            }
        }
    }

    @media only screen and (max-width: 400px) {
        .item {
            .text-container {
                width: 95%;
            }
        }
    }
}
