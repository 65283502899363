/* ANIMATION INSTRUCTIONS:
Elements with the class 'ani-play' will play their animation immediately
Elements with the class 'animateIn' will play their animation when they (or their immediate parent) becomes visible in the viewport
The classes .ani-delay-1, .ani-delay-2, etc., increase the length of the animation slightly, allowing you to stagger adjacent animations
Animations are named according to animate.css

For example:
  <div class="animateIn fadeInUp ani-delay-1"></div>
will create a div that fades in from below when scrolled to, slightly slower than the default.
*/

.animateIn {
    animation-play-state: paused;
    animation-duration: 1s;
}

.ani-play {
    animation-play-state: running;
}

.already-visible {
    animation: none;
}

.cancel-ani {
    animation-fill-mode: forwards;
}

.ani-delay-1 {
    animation-duration: 1.2s;
    animation-fill-mode: backwards;
}

.ani-delay-2 {
    animation-duration: 1.4s;
    animation-fill-mode: backwards;
}

.ani-delay-3 {
    animation-duration: 1.6s;
    animation-fill-mode: backwards;
}

.ani-delay-4 {
    animation-duration: 1.8s;
    animation-fill-mode: backwards;
}

.transition {
    transition: all 0.3s;
}

.inverted {
    transform: scaleY(-1);
    transform-style: preserve-3d;
}

.paused {
    animation-play-state: paused;

    * {
        animation-play-state: paused;
    }
}

/* Keyframes */
/* Animation for the mouse on the home page */
@keyframes mouseSlide {
    0% {
        transform: translate3d(0, 0%, 0);
        opacity: 1;
    }

    60% {
        transform: translate3d(0, 40%, 0);
        opacity: 1;
    }

    70% {
        transform: translate3d(0, 40%, 0);
        opacity: 0;
    }

    90% {
        transform: translate3d(0, 0, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

/* Animations for desktop timeline */
@keyframes draw {
    to {
        stroke-dashoffset: 0px;
    }
}

@keyframes appear {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}