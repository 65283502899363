/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

// Intro with blue icons

.icons-intro {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    padding: $standard-padding;
    background-color: $lightgrey;

    @include media-breakpoint-down(sm) {
        padding: $standard-padding-mobile;
    }

    h3 {
        margin-bottom: 1em;
        text-align: center;
    }

    .container {
        display: flex;
        justify-content: space-evenly;
        align-content: center;
        align-items: flex-start;
        width: $standard-width;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        max-width: none;

        > div {
            width: 100%;

            > div {
                padding: 0;
            }
        }

        @include media-breakpoint-down(lg) {
            width: 90%;
            padding: 0;
        }

        @include media-breakpoint-only(xs) {
            width: 100%;
        }

        .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
            height: 100%;
            padding: 0 1rem;
            transition: background-color 0.3s;

            .icon-container {
                position: relative;
                height: calc(90px + (100vw / 50));
                width: calc(90px + (100vw / 50));
                margin: 1.5rem 0 1rem 0;
                transition: all 0.2s;

                .icon-circle {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    height: 100%;
                    width: 100%;
                    border: 10px solid lighten($blue, 40%);
                    border-radius: 50%;
                    background-color: $blue;
                }

                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }

                i::before {
                    font-size: calc(2.5rem + (100vw / 100));
                    color: $lightgrey;
                }
            }

            .text-container {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: $darkgrey;



                p {
                    transition: color 0.2s;
                    text-align: center;
                    width: 100%;
                }
            }

            &:hover {

                .text-container p {
                    color: $black;
                }

                .icon-container {
                    transform: scale(1.05);
                }
            }
        }

        .item:hover .icon-container {
            transform: scale(110%);
        }

        .item:hover .text-container {
            color: $black;
        }
    }

    .bottom-text {
        width: $standard-width;
        margin-top: 3rem;
        text-align: center;
    }
}