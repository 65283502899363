/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

.btn {
    height: $button-height;
    border: none;
    border-radius: 25px;
    padding: 0 2rem;
    background-color: $blue;
    line-height: $button-height;
    transition: background-color 0.3s;

    &:hover {
        background-color: darken($blue, 10%);
    }
}
.text-btn {
    font-size: $size-p;
}