﻿/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

// 'People' specific styles
#people {
    .overlay-intro {
        .text-container {
            h4 {
                margin-right: 0;
                margin-bottom: 0.125em;
            }
            p:first-of-type {
                margin-bottom: 0.5em;
            }
        }
    }
}

.team-members {
    padding-bottom: calc(7.5vh - 2rem); /* -2rem counteracts the padding on the bottom of the directors */
    padding-top: 5rem;

    &.a {
        padding-top: 1.5rem;
    }

    .break {
        flex-basis: 100%;
        height: 0;
    }

    .directors-container-inner {
        display: flex;
        justify-content: center;
        width: 90%;

        @include media-breakpoint-down(lg) {
            width: 90%;
        }

        > .row {
            justify-content: center;
        }

        .director {
            display: flex;
            flex-direction: column;
            align-self: center;
            margin-bottom: 2rem;

            @include media-breakpoint-down(md) {
                padding: 0 0.5rem;
            }
        }

        .text-container {
            height: auto;
            text-align: center;
            margin: 2rem 0;

            hr {
                width: 75%;
                color: $lightgrey;
                background-color: $lightgrey;
                border-color: $lightgrey;
            }
        }
    }

    &.a {
        background-color: $lightgrey;
    }

    &.b {
        background-color: white;
    }
}


.directors-container-inner, .overlay-intro {
    .img-container {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        height: 100%;
        width: 100%;

        .img-container-inner {
            position: relative;
            display: inline-block;
            width: 100%;

            img {
                height: 100%;
                width: 100%;
                box-shadow: 0 0 6px rgba($black, 0.1);
            }

            .img-filter {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                opacity: 0;
                height: 25%;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                border-radius: 0 0 20px 20px;
                background-image: linear-gradient(to top, rgba(28,41,64,1), rgba(28,41,64,0));
                transition: all 0.3s;

                .filter-text-container {
                    text-align: center;
                    color: white;

                    p {
                        font-size: calc(0.875rem + (100vw / 200));
                        line-height: 130%;

                        @include media-breakpoint-down(md) {
                            font-size: calc(0.6rem + (100vw / 100));
                        }

                        @include media-breakpoint-down(xs) {
                            font-size: calc(0.8rem + (100vw / 100));
                        }
                    }

                    a {
                        color: white;
                        font-size: 2.25rem;
                    }
                }
            }

            &:hover .img-filter {
                opacity: 1;
            }
        }
    }
}

#people h2 {
    margin-bottom: 5rem;
}


#people main section:not(.page-blocks) .img-container {
    @extend .rounded;
    /* Fixes for Safari rounded corners bug */
    overflow: hidden;
    clip-path: content-box;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    
    .img-container-inner {
        transition: all 0.3s;
            &:hover {
            transform: scale(1.04);
        }
    }
}
