// Sass Variables

/* Colours */
$blue: #1367BE;
$darkblue: #12244F;
$bluegrey: #4B6197;
$darkbluegrey: #405077;
$black: #242424;
$darkgrey: #5F5F5F;
$grey: #ACACAC;
$lightishgrey: #E2E2E2;
$lightgrey: #F7F7F7;
$red: #E22A24;

$team-blue: #199cd8;
$team-pink: #e60f65;
$team-yellow: #fdca1c;
$team-green: #94c146;

$colour-primary: $blue;
$colour-headings: $black;
$colour-text: $darkgrey;
$colour-text-dark: #2d2d2d;

$white			: #FFFFFF;
$black          : #000000;
$BurntSienna    : #ED6169;

$a-tags: 'a, a:active, a:hover, a:visited';
$a-tags-hover: 'a:active, a:hover';

/* Section widths */
$small-width: calc(60px + 50vw);
$standard-width: calc(120px + 66.66vw);
$large-width: calc(120px + 75vw);
$extra-large-width: 90%;

/* Type sizes */
$size-h1: calc(2.5rem + (100vw/40));
$size-h2: calc(2.5rem + (100vw/140)); 
$size-h3: calc(2.25rem + (100vw/140));
$size-h4: calc(2rem + (100vw/200));
$size-h6: calc(1.375rem + (100vw/650));
$size-p-s: calc(0.65rem + (100vw/650));
$size-p: calc(0.95rem + (100vw/650));
$size-p-l: calc(1rem + (100vw/300));
$size-p-xl: calc(1.25rem + (100vw/300));

/* Padding */
$standard-padding: 7.5rem 0;
$standard-padding-mobile: 5rem 0;

/* Buttons */
$button-height: 50px;