﻿/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

// Bootstrap overrides

.btn-primary {
    background: $BurntSienna
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}