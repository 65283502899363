/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

.page-blocks {
    display: flex;
    width: 100%;
    background-color: $lightishgrey;

    * {
        transition: all 0.3s;
    }

    > div {
        margin: 0;

        > div {
            padding: 0;
        }
    }

    .item {
        display: inline-flex;
        flex-direction: column;
        overflow: hidden;
        height: 100%;
        width: 100%;

        a {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            border: none;
        }

        .img-container {

            img {
                width: 100%;
                border-radius: 0;
            }
        }

        .text-container {
            display: block;
            height: 100%;
            padding: 1.25rem 1rem;
            padding-bottom: 2rem;
            background-color: $lightishgrey;

            .label {
                margin-bottom: 0.25em;
                font-weight: bold;
            }

            .see-more {
                position: absolute;
                bottom: 0rem;
                opacity: 0;
                font-weight: bold;
                color: $blue;
            }
        }

        &:hover {
            background-color: white;

            .img-container {
                transform: scale(1.05) translateY(-18px);
            }

            .text-container {
                background-color: white;
                transform: translateY(-36px);

                .see-more {
                    opacity: 1;
                }
            }
        }
    }
}