/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

#home-carousel, #careers-carousel {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: auto;
    background-color: $lightgrey;
    padding: $standard-padding;

    @include media-breakpoint-down(sm) {
        padding: $standard-padding-mobile;
    }

    .carousel-indicators-container {
        width: $standard-width;
        margin-bottom: 3rem;

        @include media-breakpoint-down(sm) {
            margin-bottom: 2rem;
        }

        li {

            hr {
                display: none;
            }

            span {
                color: inherit;
            }

            &.active {
                color: $black;
            }

            &:first-of-type {
                hr {
                    display: block;
                }
            }
        }
    }

    .carousel-content {
        display: inline-block;
        height: auto;
        width: $standard-width;
        margin: 2em auto;

        @include media-breakpoint-down(md) {
            width: calc(120px + 75vw);
        }

        @include media-breakpoint-down(sm) {
            width: 90%;
        }

        @include media-breakpoint-only(xs) {
            width: 100%;
        }

        .carousel-item {
            /*display: flex;
            align-content: center;
            text-align: center;
            flex-direction: column;*/
            height: auto;
            /* The transparent borders are to add a gap between the sliding items without breaking the layout */
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
        }

        .text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            padding-right: 1rem;

            h3 {
                margin-bottom: 2rem;
            }

            p {
                margin-bottom: 2rem;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 0;
                }
            }
        }

        .img-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;

            @include media-breakpoint-up(xl) {
                float: right;
                width: 90%;
            }

            @include media-breakpoint-down(sm) {
                margin-top: 1.5rem;
                width: 80%;
            }

            @include media-breakpoint-only(xs) {
                width: 100%;
            }

            img {
                height: 100%;
                width: 100%;
            }
        }

        .btn-container {
            margin-top: 3rem;
        }
    }

    ol.carousel-indicators {
        position: static;
        width: 65%;
        text-align: center;

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        li {
            display: inline-block;
            height: 60px;
            line-height: 60px;
            width: 25%;
            margin: 0 2.5%;
            border-top: 0;
            border-bottom: 1px solid lighten($grey, 20%);
            background: initial;
            color: $grey;
            font-weight: normal;
            font-family: $font-headings;
            text-indent: 0; /* Overriding Bootstrap */
            opacity: 1; /* Overriding Bootstrap */
            transition: all 0.3s;
            transition-property: color, border-color;

            &:hover {
                color: $black;
                border-color: lighten($grey, 10%);
            }

            &.active:hover {
                color: $black;
            }

            div {
                display: none;
            }

            .carousel-progress-bar {
                height: 1px;
                background-color: $blue;
                width: 0%;
                margin: 0px 0px 0px 0px;
                border: none;
                z-index: 11;
                position: relative;
                top: -0.5px; /* Aligns with the <li> border */
                text-align: left;
            }
        }
    }

    .small-carousel-indicator {
        margin-bottom: 100px;
        text-align: center;
        height: 60px;
        line-height: 60px;
        width: 9em;
        margin: 0 2.5%;
        border-bottom: 1px solid lighten($grey, 20%);
        background: initial;
        color: $black;
        font-weight: 600;
        font-family: $font-headings;
        text-indent: 0; /* Overriding Bootstrap */
        opacity: 1; /* Overriding Bootstrap */
        transition: color 0.4s;

        .carousel-progress-bar {
            height: 1px;
            background-color: $blue;
            width: 0%;
            margin: 0px 0px 0px 0px;
            border: none;
            z-index: 11;
            position: relative;
            top: -1px;
        }
    }

    @include media-breakpoint-only(xs) {
        ol.carousel-indicators {
            margin-top: 8px;
            justify-content: center;

            li {
                /* <lis> are larger than <divs> to provide a larger clickable/tappable area to switch slides */
                position: relative;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                height: 30px;
                width: 30px;
                margin: 0 2px !important;
                background-color: transparent;
                border-radius: 50%;
                border: none;
                transition: background-color 0.4s;

                span {
                    display: none;
                }

                div {
                    @include absolute-centre;
                    display: inline-block;
                    height: 9px;
                    width: 9px;
                    background-color: $grey;
                    border-radius: 50%;
                    border: none;
                    transition: background-color 0.4s;
                }

                hr {
                    display: none;
                }

                .carousel-progress-bar {
                    display: none;
                    visibility: hidden;
                }
            }

            li.active div {
                background-color: $black;
            }
        }
    }
}

#careers-carousel {
    background-color: white;
    justify-content: center;

    h3 {
        text-align: center;
        margin-left: 15px;
        margin-right: 15px;
    }

    .small-carousel-indicator {
        height: 0px;

        span {
            display: none !important;
        }

        .carousel-progress-bar {
            top: 0;
        }
    }

    .carousel-indicators-container {
        margin: 1rem auto 0 auto;


        ol.carousel-indicators {
            margin-top: 12px;
            justify-content: center;

            li {
                /* <lis> are larger than <divs> to provide a larger clickable/tappable area to switch slides */
                position: relative;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                height: 20px;
                width: 20px;
                margin: 0 !important;
                background-color: transparent;
                border-radius: 50%;
                border: none;
                transition: background-color 0.4s;

                span {
                    display: none;
                }

                div {
                    @include absolute-centre;
                    display: inline-block;
                    height: 8px;
                    width: 8px;
                    background-color: $grey;
                    border-radius: 50%;
                    border: none;
                    transition: background-color 0.4s;
                }

                hr {
                    display: none;
                }

                .carousel-progress-bar {
                    display: none;
                    visibility: hidden;
                }
            }

            li.active div {
                background-color: $black;
            }
        }


        div.button {
            height: 20px;
            width: 20px;
            margin: 0 2px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            div {
                position: relative;
                height: 100%;
                width: 100%;
            }

            i {
                display: block;
                font-size: .75em;
                @include absolute-centre;
            }
        }
    }



    #play {
        display: none;
    }

    .carousel-inner {

        .item {


            > div {
                width: 75%;
                text-align: center;
                margin: auto auto;
                display: block;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

            .quote {
                position: relative;
                margin-bottom: 2rem;

                p {
                    @extend .text-large;
                }
            }

            .attribution {
                height: 1.1em;
                position: relative;
                overflow: visible;

                &::before {
                    content: "-";
                    position: relative;
                    left: -0.25em;
                }
            }
        }
    }
}

