/// <reference path="../abstracts/_functions" />
/// <reference path="../abstracts/_mixins" />
/// <reference path="../abstracts/_variables" />

header {
    position: relative;
    top: -80px;
    margin-bottom: -80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    height: 480px;
    min-height: calc(80px + 40vh);
    max-height: calc(80px + 50vh;
    width: 100%;
    background-position-y: 0;


    @include media-breakpoint-down(sm) {
        max-height: none;
    }

    &.fixed-nav {
        margin-top: 80px;

        @include media-breakpoint-down(md) {
            margin-top: 60px;
        }
    }

    .header-inner {
        width: 100%;
        margin-top: 1em; /* Brings title down a little to centre it vertically in the header (compensating for the hidden part of the header under the nav). */
        padding-left: 15px;
        padding-right: 15px;
        @include absolute-centre;
    }

    .header-filter {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        backdrop-filter: blur(4px);
        animation-duration: 2s;
        animation-fill-mode: forwards;
    }
    .header-fallback {
        display: none;
    }
    /*---------------*/
    .mouse-container {
        display: none;
    }
    /*---------------*/
}

header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll; /* Changing this to fixed breaks the 'cover' instruction */
    z-index: -1;
    background-position-y: inherit;
}

// Telecoms section header images
#telecoms header::before {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../images/header/header-01.jpg);
}
#direct-channel header::before {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../images/header/header-14.jpg);
}
#partner-channel header::before {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../images/header/header-11.jpg);
}
#mno header::before {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../images/header/header-10.jpg);
}

// Technology section header images
#technology header::before {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../images/header/header-02.jpg);
}

#sim-management header::before {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../images/header/header-04.jpg);
}


// About section header images
#about header::before {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../images/header/header-03.jpg);
}
#careers header::before {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../images/header/header-13.jpg);
}
#history header::before {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../images/header/header-05.jpg);
}
#global-offices header::before {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../images/header/header-07.jpg);
}
#people header::before {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../images/header/header-06.jpg);
}
#mission-values header::before {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../images/header/header-12.jpg);
}

// Contact section haeder image
#contact header::before {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../images/header/header-09.jpg);
}


// Removed privacy page header
#privacy header {
    display: none;
}
#submitted header {
    max-height: 80px;
    min-height: unset;
    visibility: hidden;
    /* The header is hidden rather than set to display:none to prevent issues with spacing from the fixed header when the user scrolls */
}

.header-video {
    display: none;
}

/* The unique header on the home page */
#home header {
    height: 100vh;
    max-height: none;

    h1 {
        padding-right: 15px;
        padding-left: 15px;
    }

    .header-video {
        display: block;
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
        z-index: -1;

        video {
            position: fixed;
            right: 0;
            bottom: 0;
            min-width: 100%;
            min-height: 100%;
            filter: brightness(70%);
        }
    }

    &::before {
        background-image: url('../images/header/header-home-mobile.jpg');
    }


    .text-container {
        margin-bottom: 2rem;
    }

    .btn {
        box-shadow: 0 0 6px rgba($black, 0.5);
    }

    .mouse-container {
        display: block;
        position: absolute;
        left: 50%;
        bottom: 7.5%;
        transform: translateX(-50%);
        height: 50px;
        width: 50px;
        cursor: pointer;

        a {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 100;
        }

        .mouse-outline {
            filter: drop-shadow(0 0 6px rgba($black, 0.5));
        }

        .mouse-dot {
            filter: drop-shadow(0 0 6px rgba($black, 0.5));
            position: relative;
            top: -10%;
            top: -100%;
            animation-name: mouseSlide;
            animation-duration: 2.5s;
            animation-iteration-count: infinite;
            animation-delay: 1s;
        }
    }
}
